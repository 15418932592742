window.__RUNTIME_CONFIG__ = {
  APIBASE: "http://localhost",
  APIPORT: "3000",
  REACT_APP_GOOGLEID: "",
  REACT_APP_SHOW_WIP: false,
  REACT_APP_GAID: "",
  REACT_APP_AUTH0_CLIENT_ID: "",
  REACT_APP_AUTH0_DOMAIN: "",
  REACT_APP_NODE_ENV: "production"
};
